import type { Config } from "tailwindcss";
/* eslint-disable */
const defaultTheme = require("tailwindcss/defaultTheme");

const config: Config = {
  content: [
    "./src/pages/**/*.{js,ts,jsx,tsx,mdx}",
    "./src/components/**/*.{js,ts,jsx,tsx,mdx}",
    "./src/app/**/*.{js,ts,jsx,tsx,mdx}",
    "./src/styles.ts",
  ],
  theme: {
    extend: {
      headline: {},
      backgroundImage: {
        "gradient-radial": "radial-gradient(var(--tw-gradient-stops))",
        "gradient-conic": "conic-gradient(from 180deg at 50% 50%, var(--tw-gradient-stops))",
      },
      width: {
        "overflow-right": "calc(100vw - ((100vw - 98.5%) / 2))",
      },
      keyframes: {
        "accordion-down": {
          from: { height: "0" },
          to: { height: "var(--radix-accordion-content-height)" },
        },
        "accordion-up": {
          from: { height: "var(--radix-accordion-content-height)" },
          to: { height: "0" },
        },
      },
      animation: {
        "accordion-down": "accordion-down 0.2s ease-out",
        "accordion-up": "accordion-up 0.2s ease-out",
      },
      colors: {
        transparent: "transparent",
        current: "currentColor",
        white: "#FFFFFF",
        "royal-blue": {
          10: "#E6EFF8",
          20: "#CCDFF1",
          60: "#669ED4",
          80: "#337EC6",
          DEFAULT: "#005EB8",
        },
        "navy-blue": {
          10: "#E6E9EF",
          20: "#CCD2DE",
          60: "#66799D",
          80: "#334D7C",
          "custom-bg-Anchor": "#E8EFF7",
          DEFAULT: "#00205B",
        },
        mellow: {
          10: "#FEFCF1",
          20: "#FEF8E3",
          60: "#FBEBAC",
          80: "#F9E491",
          DEFAULT: "#F8DD75",
        },
        black: {
          softWhite: "#FCFCFC",
          5: "#F2F2F2",
          10: "#E6E6E6",
          20: "#CCCCCC",
          40: "#999999",
          60: "#666666",
          80: "#333333",
          DEFAULT: "#000000",
        },
        dijon: {
          10: "#FBF6ED",
          20: "#F7EDDB",
          60: "#E7C894",
          80: "#DFB671",
          DEFAULT: "#D7A44D",
        },
        clay: {
          20: "#f0edea",
          40: "#e1dad5",
          60: "#D1C8BF",
          80: "#C2B5AA",
          DEFAULT: "#B3A395",
        },
        cement: {
          20: "#f5f7f7",
          40: "#ebefef",
          60: "#E2E7E6",
          80: "#D8DFDE",
          DEFAULT: "#CED7D6",
        },
        warning: {
          dark: "#BB561B",
          light: "#FACDB2",
          DEFAULT: "#FF961F",
        },
        error: {
          dark: "#841F21",
          light: "#EFB8B9",
          DEFAULT: "#DE350B",
        },
        success: {
          dark: "#115121",
          light: "#A5D1AB",
          DEFAULT: "#1C8837",
        },
        backdrop: {
          DEFAULT: "#000000A1",
        },
      },
    },
    screens: {
      xs: "475px",
      ...defaultTheme.screens,
    },
    fontSize: {
      "56": "3.5rem",
      "48": "3rem",
      "44": "2.75rem",
      "40": "2.5rem",
      "36": "2.25rem",
      "32": "2rem",
      "28": "1.75rem",
      "24": "1.5rem",
      "22": "1.375rem",
      "20": "1.25rem",
      "18": "1.125rem",
      "16": "1rem",
      "14": ".875rem",
      "12": ".75rem",
    },
    letterSpacing: ({ theme }) => ({
      ...theme("spacing"),
      tighter: "0.1px",
      wide: "0.4px",
      wider: "1px",
    }),
    lineHeight: {
      "67": "4.188rem",
      "58": "3.625rem",
      "48": "3rem",
      "42": "2.625rem",
      "40": "2.5rem",
      "38": "2.375rem",
      "36": "2.25rem",
      "32": "2rem",
      "28": "1.75rem",
      "24": "1.5rem",
      "22": "1.375rem",
      "20": "1.25rem",
      "1.2": "120%",
      "1.5": "150%",
    },
    fontWeight: {
      thin: "200",
      regular: "400",
      medium: "500",
      semibold: "600",
    },
    fontFamily: {
      saira: ["var(--font-saira)"],
      sairaCondensed: ["var(--font-saira-condensed)"],
      sairaSemiCondensed: ["var(--font-saira-semi-condensed)"],
      body: ["var(--font-saira)"],
    },
  },
  plugins: [require("tailwindcss-animate"), require("@tailwindcss/typography")],
};
export default config;
